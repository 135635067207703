<template>
  <ui-wrap-modal
    v-bind="$attrs"
    size="lg"
  >
    <div
      ref="wrapRef"
      class="flex gap-2.5 items-center relative"
    >
      <ui-button
        v-if="!loading"
        icon-left="chevron_left"
        :disabled="disabledPrev"
        variant="link"
        hide-space
        :loading="activeIdxMeta.isFirst && chatAttachmentsStore.loading.prev"
        size="xl-icon"
        class="absolute left-0 top-1/2 -translate-y-1/2 sm:text-4xl text-3xl"
        @click="onPrev"
      />
      <div class="flex-1 overflow-hidden rounded-lg fade-in-image">
        <div
          v-if="currentAttachment"
          :key="currentAttachment.id"
          class="aspect-auto h-[60vh] min-h-80 flex justify-center items-center"
        >
          <ui-file-preview
            :src="currentAttachment.url"
            :file-name="currentAttachment.name"
            wrap-class="h-auto w-auto"
            class="object-contain max-w-full max-h-full mx-auto"
          />
        </div>
      </div>
      <ui-button
        v-if="!loading"
        icon-left="chevron_right"
        variant="link"
        hide-space
        :loading="activeIdxMeta.isLast && chatAttachmentsStore.loading.next"
        :disabled="disabledNext"
        size="xl-icon"
        class="absolute right-0 top-1/2 -translate-y-1/2 sm:text-4xl text-3xl"
        @click="onNext"
      />
    </div>
    <common-room-chat-message-attachment-preview-action
      v-if="currentAttachment"
      large
      :is-author="currentAttachment.author.id === user?.id"
      :source="currentAttachment as MessageAttachment"
      class="flex-col items-center w-full px-5 gap-3 text-center mt-4"
      @remove="onRemoveAttachment"
    />
    <ui-loader :loading="loading" />
  </ui-wrap-modal>
</template>

<script setup lang="ts">
  type Props = {
    attachment: MessageAttachment;
  };
  const props = defineProps<Props>();
  const wrapRef = ref();
  const MIN_LOAD_PREV_NEXT_ATTACHMENT = 3;
  const emit = defineEmits(['update:modelValue']);
  const { onClose } = createModalState(emit);
  const chatAttachmentsStore = useChatAttachmentsStore();
  const { user } = useAuthUser();
  const currentIdx = ref(0);
  const currentAttachment = computed(() => chatAttachmentsStore.attachments[currentIdx.value]);
  const activeIdxMeta = computed(() => {
    return {
      isFirst: currentIdx.value === 0,
      isLast: currentIdx.value === chatAttachmentsStore.attachments.length - 1
    };
  });
  const loading = computed(() => chatAttachmentsStore.loading.global || !currentAttachment.value);
  const disabledPrev = computed(() => activeIdxMeta.value.isFirst && !chatAttachmentsStore.hasPrev);
  const disabledNext = computed(() => activeIdxMeta.value.isLast && !chatAttachmentsStore.hasNext);
  const allowLoadPrev = computed(() => currentIdx.value <= MIN_LOAD_PREV_NEXT_ATTACHMENT);
  const allowLoadNext = computed(
    () => currentIdx.value >= chatAttachmentsStore.attachments.length - MIN_LOAD_PREV_NEXT_ATTACHMENT
  );

  const onPrev = () => {
    if (!disabledPrev.value) {
      currentIdx.value = Math.max(currentIdx.value - 1, 0);
    }
  };

  const onNext = () => {
    if (!disabledNext.value) {
      currentIdx.value = Math.min(currentIdx.value + 1, chatAttachmentsStore.attachments.length);
    }
  };

  const onRemoveAttachment = () => {
    if (!chatAttachmentsStore.attachments.length) {
      onClose();
      return;
    }
    onPrev();
  };

  onKeyStroke('ArrowLeft', onPrev);
  onKeyStroke('ArrowRight', onNext);
  useSwipe(wrapRef, {
    onSwipeEnd(_, direct) {
      if (direct === 'left') {
        onNext();
        return;
      }
      if (direct === 'right') {
        onPrev();
      }
    }
  });

  let stopWatch = () => {};
  onMounted(async () => {
    currentIdx.value = await chatAttachmentsStore.goToAttachment(props.attachment);
    stopWatch = watch(
      [allowLoadPrev, allowLoadNext],
      ([prev, next]) => {
        if (prev) {
          chatAttachmentsStore.onLoadAttachment('prev');
          return;
        }
        if (next) {
          chatAttachmentsStore.onLoadAttachment('next');
        }
      },
      {
        immediate: true
      }
    );
  });

  onBeforeUnmount(stopWatch);
</script>
