<template>
  <component
    :is="computedPreviewData.tag"
    v-bind="{ ...$attrs, ...computedPreviewData.props }"
  >
    {{ computedPreviewData.children }}
  </component>
</template>

<script setup lang="ts">
  import getVideoID from 'get-video-id';
  import type { KFilesData } from '~/globals/FilesData';
  import { UiImage, UiFilePreviewIcon } from '#components';

  type Props = {
    file?: File;
    src?: string;
    fileType?: KFilesData;
    fileName?: string;
    isParseFile?: boolean;
    clickable?: boolean;
    loading?: 'lazy' | 'eager';
  };
  const props = withDefaults(defineProps<Props>(), {
    isParseFile: true,
    loading: 'lazy'
  });
  const emits = defineEmits(['open']);
  const previewSrc = ref(props.src || '');
  const computedType = computed(() => {
    if (props.fileType) {
      return props.fileType;
    }
    return getFileTypeByName(props.file?.name || props.src);
  });

  const defaultPreviewData = computed(() => ({
    tag: UiFilePreviewIcon,
    props: {
      type: computedType.value
    },
    children: ''
  }));

  const getClickableAttrs = () => {
    if (props.clickable) {
      return {
        class: 'cursor-pointer',
        onClick: () => emits('open')
      };
    }
    return {};
  };

  const computedPreviewData = computed(() => {
    if (!previewSrc.value) {
      return defaultPreviewData.value;
    }

    switch (computedType.value) {
      case 'img': {
        return {
          tag: UiImage,
          props: {
            src: previewSrc.value,
            disabledLazyLoad: true,
            ...getClickableAttrs()
          },
          children: null
        };
      }
      case 'audio': {
        return {
          tag: 'audio',
          props: {
            controls: true,
            src: previewSrc.value,
            loading: props.loading
          },
          children: null
        };
      }
      case 'video': {
        if (!props.file) {
          const { provider, isValid } = validateDomains(previewSrc.value, ['youtube', 'vimeo']);
          if (isValid) {
            const data = {
              tag: 'iframe',
              props: {
                src: '',
                allow:
                  'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share',
                width: '100%',
                height: '100%',
                allowfullscreen: true,
                loading: props.loading
              },
              children: ''
            };
            const { id } = getVideoID(previewSrc.value);

            switch (provider) {
              case 'youtube': {
                data.props.src = 'https://www.youtube.com/embed/' + id;
                return data;
              }
              case 'vimeo': {
                data.props.src = 'https://player.vimeo.com/video/' + id;
                return data;
              }
            }
          }
        }

        return {
          tag: 'video',
          props: {
            controls: !props.clickable,
            poster: props.clickable ? '/assets/images/placeholder-video.svg' : undefined,
            src: previewSrc.value,
            ...getClickableAttrs()
          },
          children: null,
          loading: props.loading
        };
      }
      default: {
        return defaultPreviewData.value;
      }
    }
  });

  watch(
    () => props.src,
    val => {
      if (val && !props.file) {
        previewSrc.value = val;
      }
    }
  );
  watchEffect(async onInvalidate => {
    let isUpdatePreview = true;
    onInvalidate(() => {
      isUpdatePreview = false;
    });
    if (props.isParseFile && props.file && IS_BROWSER) {
      const link = await generatePreviewLink(props.file);
      if (isUpdatePreview) {
        previewSrc.value = typeof link === 'string' ? link : '';
      }
      return;
    }
    if (!props.file) {
      previewSrc.value = props.src || '';
    }
  });
</script>

<style lang="scss" scoped>
  ::v-deep(iframe),
  video {
    @apply aspect-video w-full;
  }
  img {
    @apply aspect-auto;
  }
</style>
